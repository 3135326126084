import tableImg from '../assets/images/furniture/01-dining-tables.jpg';
import chairImg from '../assets/images/furniture/03-chairs.jpg';
import bedroomImg from '../assets/images/furniture/04-bedrooms.jpg';
import benchesImg from '../assets/images/furniture/05-benches.jpg';
import barstoolImg from '../assets/images/furniture/06-barstools.jpg';
import diningCasesImg from '../assets/images/furniture/07-cases.jpg';
import occasionalImg from '../assets/images/furniture/08-occasional.jpg';
import homeOfficeImg from '../assets/images/furniture/09-home-office.jpg';

const collection = {
  dining: [
    {
      title: 'Tables',
      image: tableImg,
      path: 'tables',
      value: 'table',
      active: true,
    },
    {
      title: 'Chairs',
      image: chairImg,
      path: 'chairs',
      value: 'chair',
      active: false,
    },
    {
      title: 'Barstool',
      image: barstoolImg,
      path: 'barstools',
      value: 'barstool',
      active: false,
    },
    {
      title: 'Benches',
      image: benchesImg,
      path: 'benches',
      value: 'benches',
      active: false,
    },
    {
      title: 'Cases',
      image: diningCasesImg,
      path: 'cases',
      value: 'dining cases',
      active: false,
    },
  ],
  bedroom: [
    {
      title: 'Bedroom',
      image: bedroomImg,
      path: 'bedroom',
      value: 'bedroom',
      active: true,
    }
  ],
  occasional: [
    {
      title: 'Occasional',
      image: occasionalImg,
      path: 'occasional',
      value: 'occasional',
      active: true,
    }
  ],
  homeOffice: [
    {
      title: 'Home Office',
      image: homeOfficeImg,
      path: 'home office',
      value: 'home office',
      active: true,
    }
  ]
};

export default collection;
