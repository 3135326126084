<template>
  <div>
    <!-- Header -->
    <div class="dp-header-top-bar">
      <div class="dp-header-top-bar-left" />
    </div>
    <div
      class="container-fluid dp-header"
    >
      <div class="row align-items-center">
        <div class="col-12 col-md-3 py-0">
          <div class="row align-items-center">
            <div class="col-2 col-md-3 mavin-logo py-0">
              <img
                :src="mavinLogo"
                width="70"
                height="54"
                alt="MAVIN"
              >
            </div>
            <div class="col-10 col-md-9 dp-logo">
              <h1 class="dp-logo-line-02">
                DESIGN<span class="pal-green">PAL</span>
              </h1>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-9 dp-nav"
        >
          <ul class="nav justify-content-end">
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.mavinfurniture.com/"
              >Home</a>
            </li>
            <li class="nav-item">
              <div class="dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </a>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/products/chairs/"
                    target="_blank"
                  >Dining Chairs</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/products/cases/"
                    target="_blank"
                  >Dining Cases</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/products/tables/"
                    target="_blank"
                  >Dining Tables</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/products/bedrooms/"
                    target="_blank"
                  >Bedroom Collections</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/products/benches/"
                    target="_blank"
                  >Benches & Barstools</a>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://storelocator.mavinfurniture.com/"
                target="_blank"
              >Store Locator</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.mavinfurniture.com/wood-guide/"
                target="_blank"
              >Wood Guide</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.mavinfurniture.com/virtual-showroom/"
                target="_blank"
              >Showroom</a>
            </li>
            <li class="nav-item">
              <div class="dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Mavin
                </a>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/our-story/"
                    target="_blank"
                  >About Us</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/product-care/"
                    target="_blank"
                  >Product Care</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/warranty-information/"
                    target="_blank"
                  >Warranty Information</a>
                  <a
                    class="dropdown-item"
                    href="https://www.mavinfurniture.com/contact/"
                    target="_blank"
                  >Contact Mavin</a>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-link-facebook"
                href="https://www.facebook.com/mavinwoods"
                target="_blank"
              >
                <font-awesome-icon
                  icon="fa-brands fa-facebook-square"
                />
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-link-instagram"
                href="https://www.instagram.com/mavinfurniture"
                target="_blank"
              >
                <font-awesome-icon
                  icon="fa-brands fa-instagram"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="dp-header-bottom-bar">
      <div class="dp-header-bottom-bar-left" />
    </div>
    <!-- / Header -->

    <!-- Main Container -->
    <div class="container-fluid dp-container">
      <div class="row">
        <!-- Main Column Left -->
        <div class="col-lg-8 dp-left-panel">
          <div class="row dp-left-panel-top-row">
            <!-- Collection Picker -->
            <div class="col-lg-8 dp-collection-picker">
              <b-button
                v-for="(t, i) in types"
                :key="i"
                :class="`button ${t.value === selected ? 'active' : ''}`"
                block
                @click="changeCollection(t)"
              >
                {{ t.text }}
              </b-button>
            </div>
            <!-- / Collection Picker -->
            <!-- Tabs -->
            <div class="col-lg-4 dp-tabs">
              <ul class="nav nav-pills nav-fill">
                <router-link
                  v-for="(item, index) in collections"
                  :key="index"
                  :to="item.path"
                  class="nav-item"
                  style="text-decoration: none;"
                >
                  <span
                    class="nav-link"
                    :class="{'active': item.active}"
                    @click="actionNav(index)"
                  >{{ item.title }}</span>
                </router-link>
              </ul>
            </div>
            <!-- / Tabs -->
          </div>
          <div class="row dp-left-panel-top-row">
            <!-- BG Image Container -->
            <ModelRender
              v-if="isModelRender"
              :base-id="baseID"
              :df="df"
              :extra-df="extraDF"
              :extra-option="extraOption"
              :label="baseLabel"
              :product-line="product_line"
              :value="baseValue"
              @showNotifications="showNotifications"
              @onTrackModelLoading="onTrackModelLoading"
            />
            <div
              v-else
              :style="{'background-image': `url(${bannerUrl})`}"
              class="col-lg-8 dp-product-img"
            />
            <!-- / BG Image Container -->
            <!-- Options Pickers -->

            <div class="col-lg-4 dp-options-picker">
              <v-overlay
                absolute
                :value="isSaveBtn && !isShowOrder && isEditMode"
              >
                <b-spinner
                  variant="success"
                  label="Spinning"
                />
              </v-overlay>
              <v-form ref="form">
                <Selector
                  :is="'Selector'"
                  v-for="(child, index) in children"
                  :id="child.id"
                  :key="index"
                  :is-edit-mode="isEditMode"
                  :is-loaded="child.isLoaded"
                  :is-show-order="isShowOrder"
                  :label="child.label"
                  :product-line="product_line"
                  :values="child.options"
                  @next="nextOption"
                />
              </v-form>
              <div
                  v-if="isShowOrder"
                  class="single-dropdown"
              >
                <label>Add a Note (Optional) - PRICING SUBJECT TO CHANGE</label>
                <b-form-textarea
                    v-model="note"
                    :state="note.length < 1000"
                    autofocus
                />
                <label style="text-align: right; padding-top: 5px;">
                  {{ `${note.length}` + " / 1000" }}
                </label>
              </div>
            </div>
            <!-- / Options Pickers -->
          </div>
        </div>
        <!-- / Main Column Left -->

        <Details
          ref="details"
          :is-collapsed="isCollapsed"
          :is-dialog="isDialog"
          :is-edit-mode="isEditMode"
          :is-save-btn="isSaveBtn"
          :is-show-order="isShowOrder"
          :product-line="product_line"
          :chair-similar="similar"
          :note="note"
          @actionSetCollection="actionSetCollection"
          @closeDialog="closeDialog"
          @editMode="editMode"
          @hideOrderBtn="hideOrderBtn"
        />
      </div>
    </div>
    <!-- / Main Container -->

    <!-- Footer -->
    <div class="container-fluid dp-footer">
      <div class="row">
        <span
          class="dp-footer__content col-12"
          v-html="$t('message.disclaimer')"
        />
        <span class="dp-footer__separate" />
        <div class="col-12">
          <small>Copyright &copy; {{ new Date().getFullYear() }} MAVIN. All Rights Reserved.</small>
        </div>
      </div>
    </div>
    <!-- / Footer -->

    <!-- Help modal -->
    <b-modal
        v-model="isShowHelpModal"
        class="dp-help-modal"
        title="How Can We Help? "
        centered
        hide-footer
    >
      <template #modal-title>
        <span
            class="dp-help-modal__title"
        >How Can We Help?</span>
      </template>
      <div class="row text-center p-3">
        <iframe
            height="699"
            title="Embedded Wufoo Form"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            style="width:100%;border:none"
            src="https://graydiantech.wufoo.com/embed/sa0df0i0i2haqi/"
        >
          <a href="https://graydiantech.wufoo.com/forms/sa0df0i0i2haqi/">Fill out my Wufoo form!</a>
        </iframe>
      </div>
    </b-modal>
    <!-- / Help modal -->

    <!-- Select notification -->
    <b-modal
        v-if="currentNotification"
        v-model="isShowNotification"
        class="dp-help-modal"
        title=""
        centered
        hide-header-close
        hide-footer
    >
      <template #modal-title>
        <span
            class="dp-help-modal__title"
        >{{ currentNotification.title }}</span>
      </template>
      <div class="px-3">
        <p>
          {{ currentNotification.content }}
        </p>
        <div class="row">
          <button
              class="btn btn-green ml-auto"
              @click="isShowNotification = false"
          >
            Acknowledge & Proceed
          </button>
        </div>
      </div>
    </b-modal>
    <!-- / Select notification -->

    <!-- Notification -->
    <notifications
      class="dp-notification"
      group="product"
      position="top right"
      animation-type="velocity"
    />
    <!-- / Notification -->
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import axios from 'axios';
import Selector from '../components/Selector/Selector';
import Details from '../components/Details/Details';
import ModelRender from "../components/ModelRender/ModelRender";
import collections from '../store/collections';
import mavinLogo from '../assets/images/mavin-logo-color.svg';

export default {
  components: {
    Details,
    ModelRender,
    Selector,
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      activeNavIndex: null,
      bannerUrl: '',
      baseID: '',
      baseLabel: '',
      baseValue: '',
      children: [],
      collections: collections.dining,
      confirmPassword: '',
      count: 0,
      currentNotification: null,
      currentPassword: '',
      df: '',
      extraDF: '',
      extraOption: [],
      editItemData: [],
      isCollapsed: false,
      isDialog: false,
      isEditMode: false,
      isSaveBtn: false,
      isShowNotification: false,
      isShowHelpModal: false,
      isShowPasswordModal: false,
      isShowOrder: false,
      mavinLogo: mavinLogo,
      newPassword: '',
      note: '',
      product_line: 'table',
      selected: 'dining',
      similar: [],
      twoTone: '',
      types: [
        {
          text: this.$t('message.diningCollection'),
          path: 'tables',
          value: 'dining'
        },
        {
          text: this.$t('message.occasionalCollection'),
          path: 'occasional',
          value: 'occasional'
        },
        {
          text: this.$t('message.bedroomCollection'),
          path: 'bedroom',
          value: 'bedroom'
        },
        {
          text: this.$t('message.homeOfficeCollection'),
          value: 'homeOffice'
        }
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getItems',
      'notifications',
    ]),
    isModelRender() {
      return this.baseID !== '';
    },
  },
  watch: {
    /**
     * Auto run the dropdown selecting with count mutation
     * @param {number} val
     */
    async count(val) {
      this.baseID = this.editItemData.base_id;
      if (this.children.length >= val) {
        if (val > 0) {
          await this.nextOption(
            this.children[val - 1].id,
            this.editItemData.options[val - 1].ID,
            '',
            '',
            '',
            true
          );
        }
      }
    },
  },
  created() {
    this.selected = this.$route.meta['collection'] || 'dining';
    this.collections = collections[this.selected];
    this.bannerUrl = this.collections[0].image;
  },
  mounted() {
    // reset product nav as first one
    if (this.$route.meta['order'] === undefined) {
      this.actionNav(0);
    } else {
      this.actionNav(this.$route.meta['order']);
    }

    // call the first select dropdown on tables line
    this.$store.dispatch("getNotifications");
    this.$store.dispatch("getSettingsData");
    this.$store.dispatch("getInitialModels");
  },
  methods: {
    /**
     * @param {number} i This should be a number of selected product index.
     */
    actionNav(i) {
      this.activeNavIndex = i;
      this.selected = this.$route.meta['collection'] || 'dining';
      this.collections = collections[this.selected];

      this.collections.forEach((item) => {
        item.active = false;
      });
      this.collections[i].active = true;
      this.bannerUrl = this.collections[i].image;
      this.product_line = this.collections[i].value;

      // set initial values on details panel
      this.baseID = '';
      this.baseValue = '';
      this.children = [];
      this.isEditMode = false; // set as true at edit mode
      this.isSaveBtn = false; // "Save" or "Add to order" at edit mode
      this.isShowOrder = false;
      this.nextOption('_INIT_', '', '', '', '', false);
    },
    /**
     * Switch collection tabs.
     * @param {string} tab This value should be dining or bedroom.
     */
    actionSetCollection(tab) {
      this.selected = tab;

      switch (this.selected) {
        case 'dining':
          this.collections = collections.dining;
          break;
        case 'bedroom':
          this.collections = collections.bedroom;
          break;
        case 'occasional':
          this.collections = collections.occasional;
          break;
        case 'homeOffice':
          this.collections = collections.homeOffice;
          break;
      }
      this.resetNav(this.collections);
      this.bannerUrl = this.collections[0].image;
      this.product_line = this.collections[0].value;

      // set initial values on details panel
      this.baseID = '';
      this.baseValue = '';
      this.children = [];
      this.isEditMode = false; // set as true at edit mode
      this.isSaveBtn = false; // "Save" or "Add to order" at edit mode
      this.isShowOrder = false;
    },
    /**
     * Switch between dining and bedroom.
     * @param {Object} tab The value should be dining or bedroom.
     */
    changeCollection(tab) {
      this.selected = tab.value;
      this.actionSetCollection(tab.value);
      this.$router.push(tab.path);
      this.nextOption('_INIT_', '', '', '', '', false);
    },
    /**
     *
     */
    closeDialog() {
      if (this.isDialog) {
        this.isDialog = false;
      }
      this.actionNav(this.activeNavIndex);
    },
    /**
     * @param {number} productIndex
     * @param {string} itemID
     */
    editMode(productIndex, itemID) {
      this.count = 0;
      this.editItemData = this.getItems[itemID];
      this.actionNav(productIndex);
      this.isEditMode = true;
      this.isSaveBtn = true;
    },
    /**
     *
     */
    hideOrderBtn() {
      if (this.isShowOrder) {
        this.isShowOrder = false;
        this.isSaveBtn = false;
      }

      if (['bedroom', 'occasional'].includes(this.selected)) {
        this.activeNavIndex = 0;
      }
      this.actionNav(this.activeNavIndex);
    },
    /**
     * @param {boolean | false} isLoading
     */
    onTrackModelLoading(isLoading = false) {
      const len = this.children.length - 1;
      this.children[len].isLoaded = !isLoading;
      if (len === 0) {
        this.children[len].isLoaded = true;
      }
    },
    /**
     * @param {string} id BASE_ID
     * @param {string} value ID_NUM
     * @param {string} v LABEL
     * @param {string} df DESC_FRAG
     * @param {string} label LABEL if PARENT_ID == '00000000'
     * @param {boolean} isEditMode
     */
    async nextOption(id, value, v, df, label, isEditMode) {
      let curOptionOffset = this.children.findIndex(el => el.id === id);
      let $details = this.$refs.details;
      let $selectors = this.$refs.form.$children;
      let extraID = '';
      let extraIndex = 0;
      let extraDF = '';
      let prevDF = '';
      let is_rustic = false;

      this.baseLabel = label;
      this.df = df;

      this.isEditMode = isEditMode;

      if (value === '_CUSTOM_') {
        $details.orderEnable(false, []);

        await $details.setOptions(
            this.children.map((option, index, arr) => {
              if (index <= curOptionOffset) {
                return Object.assign(
                    {
                      label: option.label,
                      extra: option.extra
                    },
                    $selectors[index].$data.select
                );
              } else {
                arr[index].options = [];
                arr[index].extra = [];
                return null;
              }
            })
        );

        return;
      }
      this.children.forEach((option, index) => {
        if (option.options.length) {
          $selectors[index].setDisabled(true);
        }
      });

      if (this.baseID === '' || curOptionOffset === 0) {
        this.baseID = value;
      }

      let ids = this.children
          .slice(0, curOptionOffset + 1)
          .map((option, index, arr) => {
            // TODO: set vue elements by edit options data at edit mode
            if (this.isEditMode) {
              // edit functionality issue at extra data
              if ((
                      this.editItemData.options[index]['IDR'] === 'NO OPTION'
                      || this.editItemData.options[index]['IDR'] === 'LG THUMBNAIL EDGE'
                  )
                  && option.extra.length
              ) {
                this.editItemData.options.splice(index, 1);
              }
              $selectors[index].$data.select = this.editItemData.options[index];
              $selectors[index].$data.selected = this.editItemData.options[index];
            }

            if (option.extra.length) {
              extraIndex = index;
              arr.concat(option.extra.map(option => {
                extraID = option.ID;
                extraDF = option.DF;

                return extraID;
              }));
            }

            return $selectors[index].$data.select.ID;
          })
          .slice(1);

      if (extraID !== '' && ids.length === 3) {
        ids.splice(extraIndex - 1, 0, extraID);
      }

      if (curOptionOffset > 0) {
        prevDF = $selectors[curOptionOffset - 1].$data.selected.DF
      }

      if (df === '2TT' || this.extraDF === '2TT' || prevDF === '2TT') {
        this.twoTone = v;
      }

      if (label === 'Select Species') {
        is_rustic =  df === '-F' || df === '-R';
      }

      await axios
        .get(`${process.env.VUE_APP_API_HOST}/api/public/products`, {
          params: {
            product_line: this.product_line,
            base_id: this.baseID,
            df: df,
            extra_df: extraDF,
            is_rustic,
            two_tone: this.twoTone,
            id:
              curOptionOffset <= 0
                ? ['00000000']
                : ids
          }
        })
        .then(response => {
          // eslint-disable-next-line no-prototype-builtins
          if (!response.data.hasOwnProperty('extra')) {
            response.data.extra = [];
          }

          if (response.data.label === '_COMPLETED_') {
            this.isShowOrder = true;
            this.note = '';
            $details.orderEnable(true, response.data.hasOwnProperty.call('similar')
              ? response.data.similar
              : []);

            if (response.data.similar) {
              this.isDialog = true;
              this.similar = response.data.similar;
            }
          } else {
            this.isShowOrder = false;
            this.isDialog = false;
            $details.orderEnable(false, []);
            if (curOptionOffset > -1) {
              response.data.isLoaded = true; // track current model loading status, true if loaded
            }

            let optionOffset = curOptionOffset + 1;
            this.children.splice(optionOffset, 1, response.data);

            if (id === '_INIT_') {
              return;
            }
          }

          this.extraDF = '';
          this.extraOption = [];
          if (response.data.extra.length > 0) {
            this.extraOption = response.data.extra;
            response.data.extra.forEach(e => {
              if (e.DF) {
                this.extraDF = this.extraDF + e.DF;
              }
            })
          }

          $selectors.forEach((option, index) => {
            if (index <= curOptionOffset + 1) {
              option.setDisabled(false);
            } else {
              this.children[index].options = [];
              this.children[index].extra = [];
            }
          });

          $details.setOptions(
            this.children
              .slice(0, curOptionOffset === -1 ? 1 : curOptionOffset + 1)
              .map((option, index) =>
                Object.assign(
                  {
                    label: option.label,
                    extra: option.extra
                  },
                  $selectors[index].$data.select
                )
              )
              .concat(response.data.extra)
          );

          // choose the first option for model render.
          if (response.data.label === 'Select Bedroom Model') {
            this.df = this.df + response.data.options[0].DF;
          }
        })
        .catch(() => {});

      this.baseValue = v;

      // auto-increase the dropdown selector's number at edit mode
      if (this.isEditMode) this.count += 1;
    },
    /**
     * @param {Object} data
     */
    resetNav(data) {
      data.forEach((col, i) => {
        col.active = i === 0;
      })
    },
    /**
     * @param {Object} n of notification title and content
     */
    showNotifications(n) {
      this.currentNotification = n;
      this.isShowNotification = true;
    },
  }
}

</script>

